import {
  CardHeader,
  Typography,
  Grid,
  makeStyles,
  Theme,
  Box,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { Fragment } from 'react';
import { useRecoilValue } from 'recoil';
import { Trip, TripProviderSource, TripWaypointRole } from 'src/.gen/graphql';
import { userAtom } from 'src/authentication/atoms/AuthState';
import Dinero, { Currency } from 'dinero.js';
import getSymbolFromCurrency from 'currency-symbol-map';
import TripStatusBox from '../TripListTile/TripStatusBox';
import SavoyaStyledLink from '../TripPassengerCard/SavoyaStyledLink';
import TripActionsMenu from '../TripActionsMenu';
import { tripDateTimeFormat } from '../../utils/tripDateTimeFormat';
import type { SavoyaVendorAssignment } from '../../types/SavoyaTripsTypes';

interface MapTileCardHeaderProps {
  trip: Partial<Trip>;
  savoyaReservationAssignment?: SavoyaVendorAssignment;
  savoyaQuotedTime?: number;
  loading?: boolean;
  isAvailableTrip: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
  cardHeader: {
    padding: 0,
  },
  groundworkLogo: {
    width: '1.5rem',
    filter: 'brightness(0%)',
    marginTop: '0.3rem',
    marginRight: '0.5rem',
    verticalAlign: 'middle',
  },
  sharedTripLabel: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
  },
  tripStatus: {
    marginBottom: 'auto',
  },
  blackText: {
    color: theme.palette.text.primary,
  },
  grayText: {
    color: theme.palette.text.secondary,
  },
}));

const MapTileCardHeader: React.FC<MapTileCardHeaderProps> = ({
  trip,
  savoyaReservationAssignment,
  savoyaQuotedTime,
  loading = false,
  isAvailableTrip = false,
}) => {
  const classes = useStyles();
  const { estimatedRevenue = 0.0, currencyCode = 'USD' } =
    savoyaReservationAssignment || {};
  const user = useRecoilValue(userAtom);
  const iOwnThisTrip = user?.accountId === trip?.accountId;
  const EstimatedRevenue = ({
    currencyCode,
    estimatedRevenue,
    isAvailableTrip,
    loading,
  }: {
    currencyCode: string;
    estimatedRevenue: number;
    isAvailableTrip: boolean;
    loading: boolean;
  }) => {
    if (isAvailableTrip) {
      return null;
    }
    return !loading ? (
      <SavoyaStyledLink
        label={`Estimated Revenue: ${currencyCode} ${
          Number(estimatedRevenue / 100).toFixed(2) ?? '-'
        }`}
        loading={loading}
      />
    ) : (
      <Skeleton />
    );
  };

  const savoyaQuotedTimeText = (quotedTime: number): string | null => {
    if (quotedTime) {
      return ` - ${quotedTime} hour(s) trip time`;
    }
    return null;
  };

  const currencySymbol =
    getSymbolFromCurrency(savoyaReservationAssignment?.currencyCode) || '$';

  const lostRevenueText = savoyaReservationAssignment?.estimatedRevenue
    ? `Lost Revenue: ${currencySymbol}${Dinero({
        amount: savoyaReservationAssignment?.estimatedRevenue,
        currency: savoyaReservationAssignment?.currencyCode
          ? (savoyaReservationAssignment?.currencyCode as Currency)
          : 'USD',
      })
        .toUnit()
        .toFixed(2)}`
    : undefined;

  return (
    <CardHeader
      data-testid="trip_passenger_card_header"
      className={classes.cardHeader}
      subheader={
        <Box flexDirection="row">
          <Typography
            variant="caption"
            className={classes.blackText}
            data-testid="trip_number"
          >
            <EstimatedRevenue
              currencyCode={currencyCode}
              estimatedRevenue={estimatedRevenue}
              isAvailableTrip={isAvailableTrip}
              loading={loading}
            />
            {!loading ? (
              <SavoyaStyledLink
                label={`Vehicle Type: ${trip?.preferredVehicleClass?.name}`}
                loading={loading}
              />
            ) : (
              <Skeleton />
            )}
            {!loading && trip?.isShared && iOwnThisTrip && !isAvailableTrip ? (
              <span className={classes.sharedTripLabel}>Shared Trip</span>
            ) : (
              <Fragment />
            )}
            {!loading && trip?.isShared && !iOwnThisTrip && !isAvailableTrip ? (
              <span className={classes.sharedTripLabel}>Received Trip</span>
            ) : (
              <Fragment />
            )}
            {!loading && trip?.isShared && !iOwnThisTrip && isAvailableTrip ? (
              <span className={classes.sharedTripLabel}>
                Available Trip
                {savoyaQuotedTimeText(
                  savoyaQuotedTime ||
                    savoyaReservationAssignment?.reservation?.quotedTime,
                )}
              </span>
            ) : (
              <Fragment />
            )}
          </Typography>
        </Box>
      }
      title={
        <Grid container data-testid="trip_pickup_time">
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {loading && <Skeleton />}
              {!loading &&
                tripDateTimeFormat(
                  trip?.pickupTime,
                  trip?.waypoints[0]?.savoyaStop?.city?.timezone,
                )}
            </Typography>
          </Grid>
        </Grid>
      }
      action={
        !loading && (
          <Grid container direction="column" alignItems="flex-end">
            {trip && (
              <Grid item className={classes.tripStatus}>
                <TripStatusBox
                  status={trip?.status}
                  savoyaSharedStatus={trip?.sharedTrip?.savoyaAssignmentStatus}
                  sharedStatus={trip?.sharedTrip?.status}
                  iOwnThisTrip={iOwnThisTrip}
                  isSavoyaTrip={
                    !!trip?.providerExternalId ||
                    trip?.providerSource === TripProviderSource.Savoya
                  }
                  isAvailableTrip={isAvailableTrip}
                />
                {/* Temporary Unblocking Brendan from testing trip flow */}
                {process.env.REACT_APP_ENVIORNMENT !== 'production' &&
                !isAvailableTrip ? (
                  <TripActionsMenu
                    type="WithCommands"
                    tripId={trip.id}
                    tripStatus={trip.status}
                    savoyaSharedTripStatus={
                      trip?.sharedTrip?.savoyaAssignmentStatus
                    }
                    tripNumber={trip.tripNumber}
                    isSavoya={trip.providerSource === TripProviderSource.Savoya}
                    savoyaTripId={
                      trip?.providerExternalId || trip?.tripNumber?.toString()
                    }
                    isShared={trip.isShared}
                    isNextDropOff={
                      trip?.currentOrNextWaypoint?.role ===
                      TripWaypointRole.Dropoff
                    }
                    availableCommands={
                      trip.isShared
                        ? trip.sharedAvailableCommands
                        : trip.availableCommands
                    }
                    savoyaLostRevenueText={lostRevenueText}
                  />
                ) : null}
              </Grid>
            )}
            {!loading ? (
              <Grid container direction="row" justify="flex-end">
                <Grid container item xs={10}>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Typography
                        variant="caption"
                        className={classes.grayText}
                      >{`Reservation #: ${trip?.tripNumber ?? ''}`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Typography
                        variant="caption"
                        className={classes.grayText}
                      >
                        {trip?.referenceNumber
                          ? `Confirmation #: ${trip?.referenceNumber}`
                          : null}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                {trip?.groundworkTrip ? (
                  <Grid item xs={2}>
                    <Box display="flex" justifyContent="flex-end">
                      <img
                        alt="Savoya"
                        src="/static/images/GroundworkLogo.svg"
                        className={classes.groundworkLogo}
                      />
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
            ) : (
              <Skeleton />
            )}
          </Grid>
        )
      }
    />
  );
};

export default MapTileCardHeader;
